<template>
    <page-section class="page-section--xs pb-0" v-if="carts.length">
        <div>
            <div v-for="(cart, index) in carts" :key="index" :class="{ 'mt-4px mt-md-8px': index != 0 }">
                <v-card outlined color="grey lighten-4" class="product-cart__card txt txt--xs">
                    <div class="pa-10px pa-md-16px">
                        <v-row class="row--xs">
                            <v-col cols="12" md="auto" class="mb-4px mb-dm-0">
                                <div class="pr-md-4px">
                                    <v-img :src="cart.src" width="60" max-width="60" :aspect-ratio="1 / 1" class="rounded">
                                        <template #placeholder>
                                            <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                        </template>
                                    </v-img>
                                </div>
                            </v-col>
                            <v-col>
                                <div class="mb-8px mb-md-12px">{{ $decode__productOptionName(cart.name) }} <span v-if="cart.stockEnabled && cart.stock == 0">(품절)</span></div>
                                <v-row no-gutters align="center">
                                    <v-col>
                                        <!-- 옵션 수량 -->
                                        <div class="spinner">
                                            <v-btn icon tile class="v-size--xx-small transparent" @click="decAmount(cart)"><v-icon color="grey">mdi-minus</v-icon></v-btn>
                                            <input v-model="cart.amount" class="spinner__num" type="number" @change="changeAmount(cart)" :disabled="cart.stockEnabled && !cart.stock" />
                                            <v-btn icon tile class="v-size--xx-small transparent" @click="incAmount(cart)"><v-icon color="grey">mdi-plus</v-icon></v-btn>
                                        </div>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span class="d-flex align-center">
                                            <strong class="font-size-16">{{ (cart.salePrice * cart.amount).format() }}</strong>
                                            <span class="pl-2px">원</span>
                                        </span>
                                    </v-col>
                                    <v-col cols="auto" v-if="cart._option || cart._supply || cart._related || clearable">
                                        <div class="pl-6px">
                                            <v-btn x-small icon class="transparent mr-n4px mr-md-n10px" @click="remove(index)">
                                                <v-icon class="font-size-18 grey--text text--lighten-1">mdi-close</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <!-- <div class="mt-4px mt-md-8px">
                            <v-text-field v-model="cart.requestMessage" dense outlined hide-details placeholder="제품요청사항" @input="emit" />
                        </div> -->
                    </div>
                </v-card>
            </div>
        </div>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        PageSection,
    },
    props: {
        value: { type: Array, default: () => [] }, // selected
        clearable: { type: Boolean, default: false },
    },
    data: () => ({
        carts: [],
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.carts = [...this.value].map((item) => {
                let src = item?.related?.thumb?.url || item?.product?.thumb?.url;

                if (!src && item?.product?.variation?.enabled) {
                    if (item?.product?.variation?.head?.thumb?.url) {
                        src = item?.product?.variation?.head?.thumb?.url;
                    }
                }

                return { ...item, src };
            });
        },
        emit() {
            this.$emit("input", this.carts);
        },
        incAmount(cart) {
            if (!cart.StockEnabled || cart.amount < cart.stock) cart.amount++;
            this.emit();
        },
        decAmount(cart) {
            if (1 < cart.amount) cart.amount--;
            this.emit();
        },
        changeAmount(cart) {
            if (cart.amount < 1) cart.amount = 1;
            if (cart.stockEnabled && cart.stock < cart.amount) cart.amount = cart.stock;
            this.emit();
        },
        remove(index) {
            this.carts.splice(index, 1);
            this.emit();
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .product-cart__card {
        border-width: 0 !important;
        box-shadow: 0 0 10px var(--shadow) !important;
    }
}
@media (min-width: 1200px) {
}
</style>
