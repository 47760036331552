<template>
    <page-section class="page-section--xs pb-0" v-if="items.length">
        <tit-wrap-x-small title="옵션선택" />
        <template v-for="({ name, values }, index) in items">
            <v-select v-model="selected[index]" @change="emit(index)" :placeholder="name" :items="values" :disabled="!!index && !selected[index - 1]" item-text="name" item-value="name" dense outlined hide-details :class="{ 'mt-1 mt-md-2': index != 0 }" :key="name" />
        </template>
    </page-section>
</template>

<script>
import { initProduct } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

export default {
    components: {
        PageSection,
        TitWrapXSmall,
    },
    props: {
        value: { type: Array, default: () => [] }, // carts
        product: { type: Object, default: initProduct },
    },
    data: () => ({
        selected: [],
    }),
    computed: {
        variations() {
            return this.product?.variation?.list || [];
        },
        items() {
            return this.product.variation.spec.map((name, index) => {
                const values = this.variations.reduce((items, item) => {
                    if (!item.sells) return items;

                    if (0 < index) {
                        let matches = !this.selected.slice(0, index).some((value, index) => item?.variation?.spec?.[index] != value);
                        if (!matches) return items;
                    }

                    return [...items, item?.variation?.spec[index]];
                }, []);

                values.sort();
                return { name, values };
            });
        },
    },
    methods: {
        emit(index) {
            this.selected = this.selected.slice(0, index + 1);

            const item = this.variations.find((product) => !product.variation.spec.some((value, index) => value != this.selected[index]));
            if (item) {
                if (item?.stockEnabled && item?.stock < 1) {
                    alert("재고가 없는 상품입니다.");
                    return;
                }

                const index = this.value.findIndex(({ _product }) => _product == item._id);
                if (-1 < index) {
                    const carts = [...this.value];
                    carts[index].amount += 1;
                    if (!carts[index].stockEnabled || carts[index].amount <= carts[index].stock) {
                        this.$emit("emit", carts);
                    }
                } else {
                    const cart = {
                        _product: item?._id,
                        product: { ...item, variation: { ...item.variation, head: this.product } },
                        name: item?.name,
                        price: item?.price,
                        salePrice: item?.salePrice,
                        discountPrice: item?.discountPrice,
                        stock: item?.stock,
                        stockEnabled: item?.stockEnabled,
                        amount: 1,
                    };
                    this.$emit("push", cart);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
// btn-group
.btn-group {
    flex-wrap: wrap;
    margin: -4px;
    .v-btn {
        line-height: 1;
        opacity: 1;
        &.v-size--x-small.v-btn--icon {
            position: relative;
            width: 34px !important;
            height: 34px !important;
            padding: 0 !important;
        }
    }
    &--color {
        .v-btn {
            border-radius: 100% !important;
            overflow: hidden;
            border: 0 !important;
            &--active {
                &::before {
                    display: none;
                }
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    border: 2px solid var(--v-grey-darken4) !important;
                }
            }
        }
    }
    &__colorchip {
        width: 30px;
        height: 30px;
        border-radius: 100%;
    }
}
</style>
